<template>
  <div class="journal-message">
    <div v-html="body"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: ''
    }
  },
  mounted() {
    if (this.category === 'error.platforms.forms.parser') {
      const json = JSON.parse(this.message)
      this.body = `
        <h6>${json.message}</h6>
        <h6>${json.platformUrl}</h6>
        <div>${json.description}</div>
        <img style="width: 100%; margin-top: 10px" src="data:image/png;base64,${json.firstScreenshot}" alt="First Screenshot"/>
      `
    } else {
      this.body = this.message
    }
  },
  props: ['category', 'message']
}
</script>
