<template>
  <div class="journal">
    <b-input class="mb-2 p-2" placeholder="Search" v-model="search"></b-input>
    <b-card class="mb-2" v-for="(filter_value, filter_key, filter_index) in logs" :key="filter_index">
      <template #header>
        <h6 class="mb-0">Category: {{ filter_value.category }}</h6>
      </template>
      <journal-message :category="filter_value.category" :message="filter_value.message" />
      <template #footer>
        <em>Date: {{ formatDate(filter_value.created_date) }}</em>
      </template>
    </b-card>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import JournalMessage from '@/agGridV2/components/journal-message.component.vue'

export default {
  components: {
    JournalMessage
  },
  data() {
    return {
      logs: [],
      search: ''
    }
  },
  async mounted() {
    this.init()
    this.$emitter.on('journal_update', async (event) => {
      this.init()
    })
  },
  watch: {
    search() {
      this.init()
    }
  },
  methods: {
    init() {
      (async () => {
        this.logs = await MtApi.getJournalRecords({
          count: 10,
          search: this.search
        })
      })()
    },
    formatDate(date) {
      const options = { month: 'numeric', day: 'numeric', year: 'numeric' }
      return new Date(date).toLocaleDateString(undefined, options)
    }
  }
}
</script>
