<template>
  <div class="part-platform-selector">
    <v-select
      class="custom-selector"
      v-model="selectedPlatform"
      :options="platforms"
      :clearable="false"
      label="url"
    >
      <template v-slot:option="option">
        <span>
          <b-icon v-if="option.setup" icon="check-circle" color="green"></b-icon>
          <b-icon v-if="!option.setup" icon="x-circle" color="red"></b-icon>
          {{ option.url }}
        </span>
      </template>
      <template #selected-option="option">
        <span>
          <b-icon v-if="option.setup" icon="check-circle" color="green"></b-icon>
          <b-icon v-if="!option.setup" icon="x-circle" color="red"></b-icon>
          {{ option.url }}
        </span>
      </template>
    </v-select>
    <b-button v-if="selectedPlatform._id !== 'all'" class="site-button" variant="primary" @click="goToSite"><b-icon icon="globe"></b-icon></b-button>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'part-platform-selector',
  components: { vSelect },
  data() {
    return {}
  },
  computed: {
    platforms() {
      return this.getPlatforms()
    },
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      },
      async set(value) {
        this.setSelectedPlatform(value)
        await this.$store.dispatch('loadAndSetDefaultLeadCenterDate')
        this.$emitter.emit('header_addons_changed')
        this.$emitter.emit('header_reset_filters')
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSelectedPlatform'
    ]),
    ...mapGetters([
      'getPlatforms',
      'getSelectedPlatform'
    ]),
    goToSite() {
      window.open(
        `https://${this.selectedPlatform.url}`,
        '_blank'
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.part-platform-selector {
  display: flex;
  gap: 10px;
  align-items: center;
}
.site-button {
  height: 100%;
  border-radius: 0;
}
</style>
