import { render, staticRenderFns } from "./platform-selector.part.vue?vue&type=template&id=31f8e46a&scoped=true&"
import script from "./platform-selector.part.vue?vue&type=script&lang=js&"
export * from "./platform-selector.part.vue?vue&type=script&lang=js&"
import style0 from "./platform-selector.part.vue?vue&type=style&index=0&id=31f8e46a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f8e46a",
  null
  
)

export default component.exports