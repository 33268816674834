<template>
  <div class="part-active-platform">
    <label for="setup">Active Platform:</label>
    <b-form-checkbox
      v-model="selectedPlatform.setup"
      id="setup"
      name="setup"
      switch
      @change="editSettings('setup', selectedPlatform.setup)"
    >
    </b-form-checkbox>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'part-active-platform',
  data() {
    return {}
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  methods: {
    ...mapMutations([
      'setSelectedPlatform'
    ]),
    ...mapGetters([
      'getPlatforms',
      'getSelectedPlatform'
    ]),
    async editSettings(name, value) {
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'update_platform',
        field: {
          name,
          value
        }
      })
      await this.$store.dispatch('updateAndSetPlatform', {
        name,
        value
      })
    }
  }
}
</script>
